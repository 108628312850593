import React, { useEffect, useState, useRef } from "react";
import { useMemo } from "react";

import { TooltipBubble, TooltipMessage, TooltipTrigger, TooltipContainer } from "./tooltip/TooltipStyle";

export const Tooltip = ({ message, position, width, children, className, minWidth, maxWidth, left }) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(0);

  /**
   * Reference to the children component to track width and height
   */
  const nodeTrigger = useRef();

  const bubbleWidth = useMemo(() => {
    if (nodeTrigger.current) {
      const rect = nodeTrigger.current.getBoundingClientRect();
      return window.innerWidth - rect.right + rect.width;
    }
    return 0;
  }, [nodeTrigger.current]);

  useEffect(() => {
    setOffsetHeight(nodeTrigger.current.offsetHeight);
  }, [nodeTrigger]);

  return (
    <TooltipContainer
      onMouseLeave={() => setDisplayTooltip(false)}
      className={`tooltip-container ${className || ""}`}
    >
      {displayTooltip && (
        <TooltipBubble
          className="tooltip-bubble"
          position={position}
          offsetHeight={offsetHeight}
          minWidth={minWidth}
          maxWidth={maxWidth}
          width={width}
          bubbleWidth={bubbleWidth}
          left={left}
        >
          <TooltipMessage>{message}</TooltipMessage>
        </TooltipBubble>
      )}
      <TooltipTrigger onMouseOver={() => setDisplayTooltip(true)} ref={nodeTrigger}>
        {children}
      </TooltipTrigger>
    </TooltipContainer>
  );
};
