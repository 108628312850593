// Libs
import ApiCall from "utils/apiCall";
import * as Sentry from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";

// Actions
import { setAlertMessage, verify } from "utils/actions";
import { closeModal, clearSelectedProduct } from "./ui";
import { updatePaywallView } from "utils/actions/tracker";

// Utils
import { postUpgradeTrackingEvents } from "utils/trackingEvents/upgradeEvents";
import { formatErrorMessage } from "utils/formatErrorMessage";
import { triggerConversionEvent } from "utils/blueTagEvents";

// Services
import { createSubscription, deletePendingSubscription } from "utils/api/payments/requests";
import { isCreditCardPaymentMethod } from "types/payments";

import paths from "routes/paths";
import { openLink } from "utils/openLink";
import { FETCH_USER_STORES_QUERY } from "utils/api/store/types";
import { NewQueryClient } from "utils/queryClient";

export const UPGRADE_URL = "UPGRADE_URL";
export const UPGRADE_ERROR = "UPGRADE_ERROR";
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const IS_UPGRADING = "IS_UPGRADING";
export const MODAL_UPGRADE_TYPE = "MODAL_UPGRADE_TYPE";

const confirmCardPayment3ds = async ({
  clientSecret,
  paymentMethod,
  subscriptionId,
  showUpgradeSuccessModal,
  dispatch,
  navigate,
  storeId
}) => {
  const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const { error } = await stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethod });
  if (error) {
    await deletePendingSubscription({ storeId });
    await NewQueryClient.invalidateQueries([FETCH_USER_STORES_QUERY]);
    error.origin = "3ds-error";
    console.error(error);
    return Promise.reject(error);
  } else {
    dispatch(verify(navigate));
    showUpgradeSuccessModal({
      subscriptionId,
      subscriptionConfirmationUrl: null
    });
    triggerConversionEvent();
  }
};

export function handleUpgradeRequest({
  planID,
  tracker,
  plan,
  listing,
  navigate,
  couponUid,
  createStoreAfterUpgrade = false,
  hCaptchaToken,
  usePromotionalPlan = false,
  userHasUpgradedFromModal = false
}) {
  return function(dispatch, getState) {
    // State from Redux store
    const { paymentProvider, storeId } = getState().settings;
    const { eventId } = getState().eventTracker;
    const userUpgradedToAnnualPlan = plan.annual;

    const shouldUseNewModal =
      process.env.REACT_APP_USES_NEW_ANNUAL_PROMOTION_MODAL === true ||
      process.env.REACT_APP_USES_NEW_ANNUAL_PROMOTION_MODAL === "true";

    // This function will be triggered after the upgrade.
    const showUpgradeSuccessModal = async ({ subscriptionId, subscriptionConfirmationUrl }) => {
      const hasSubscriptionConfirmationUrl =
        subscriptionConfirmationUrl !== undefined &&
        subscriptionConfirmationUrl !== null &&
        subscriptionConfirmationUrl &&
        subscriptionConfirmationUrl.length > 0;

      if (!hasSubscriptionConfirmationUrl) postUpgradeTrackingEvents({ plan, subscriptionId, listing });

      localStorage.removeItem("landing_page_signup");

      dispatch(modalClose());
      dispatch(isUpgrading(false));

      if (plan.alias === "Promotional") {
        plan.alias = "Pro";
      }

      if (paymentProvider === "shopify_payment" || paymentProvider === "wix_payment") {
        return openLink(subscriptionConfirmationUrl, "_self");
      } else {
        // Annual Promotion Current Modal
        if (shouldUseNewModal) {
          // If user has upgraded through promotion modal, they won't see anything more
          const shouldDisplayAnnualPromotion = userHasUpgradedFromModal ? null : "ANNUAL_PROMOTION_MODAL";

          const modalToBeDisplayed = userUpgradedToAnnualPlan
            ? "UPGRADE_SUCCESS"
            : shouldDisplayAnnualPromotion;

          if (userHasUpgradedFromModal) {
            dispatch(closeModal());
          }

          // Post Upgrade redirect
          let paramsHash = {
            plan_name: plan.name,
            annual: plan.annual,
            has_subscription_confirmation_url: hasSubscriptionConfirmationUrl,
            confirmation_url: subscriptionConfirmationUrl
          };
          if (modalToBeDisplayed !== null) {
            paramsHash = {
              ...paramsHash,
              upgrade_modal: modalToBeDisplayed
            };
          }
          const queryString = Object.keys(paramsHash)
            .map(key => key + "=" + paramsHash[key])
            .join("&");
          navigate(`${paths.settings.chargebeePaymentSuccess}?${queryString}`); // TODO: make hook or sth
        } else {
          // Annual Promotion Old Modal
          const hasSubscriptionConfirmationUrl =
            subscriptionConfirmationUrl !== undefined &&
            subscriptionConfirmationUrl !== null &&
            subscriptionConfirmationUrl &&
            subscriptionConfirmationUrl.length > 0;
          // Post Upgrade redirect
          const modalToBeDisplayed = userUpgradedToAnnualPlan ? "UPGRADE_SUCCESS" : "ANNUAL_PROMOTION_MODAL";
          const paramsHash = {
            upgrade_modal: modalToBeDisplayed,
            plan_name: plan.name,
            annual: plan.annual,
            has_subscription_confirmation_url: hasSubscriptionConfirmationUrl,
            confirmation_url: subscriptionConfirmationUrl
          };
          const queryString = Object.keys(paramsHash)
            .map(key => key + "=" + paramsHash[key])
            .join("&");

          const url = `${paths.settings.chargebeePaymentSuccess}?${queryString}`;

          navigate(url); // TODO: make hook or sth
        }
      }
    };

    // Building the requestPayload to be send to createPlanCharge() and createSubscription() actions
    const requestPayload = shouldUseNewModal
      ? {
          plan_id: planID,
          coupon_uid: couponUid,
          upgrade_with_store: createStoreAfterUpgrade,
          use_promotional_plan: usePromotionalPlan,
          tracking_plan_upgrade: {
            ...tracker
          },
          "h-captcha-response": hCaptchaToken
        }
      : {
          plan_id: planID,
          coupon_uid: couponUid,
          upgrade_with_store: createStoreAfterUpgrade,
          tracking_plan_upgrade: {
            ...tracker
          },
          "h-captcha-response": hCaptchaToken
        };

    if (eventId) {
      dispatch(
        updatePaywallView({
          eventId,
          upgradeStep: "process_upgrade"
        })
      );
    }

    return createSubscription({ storeId, payload: requestPayload })
      .then(async res => {
        const { data } = res || {};
        const {
          id: subscriptionId,
          confirmation_url: subscriptionConfirmationUrl,
          last_payment_client_secret: clientSecret,
          payment_method: paymentMethod
        } = data;

        if (clientSecret) {
          const { uid: paymentMethodId } = paymentMethod;
          return confirmCardPayment3ds({
            clientSecret,
            subscriptionId,
            paymentMethod: paymentMethodId,
            showUpgradeSuccessModal,
            dispatch,
            navigate,
            storeId
          });
        }

        dispatch(verify(navigate));
        showUpgradeSuccessModal({ subscriptionId, subscriptionConfirmationUrl });

        triggerConversionEvent();
      })
      .catch(error => {
        if (isCreditCardPaymentMethod(paymentProvider)) {
          const errMessage = formatErrorMessage(error);
          Sentry.captureException(`Chargebee Subscription Error - ${errMessage}`);
        }
        return Promise.reject(error);
      });
  };
}
export function handleUpgradeRequestSwitchStore({
  planID,
  tracker,
  plan,
  navigate,
  couponUid,
  createStoreAfterUpgrade = false,
  hCaptchaToken,
  storeId
}) {
  return function(dispatch, getState) {
    // State from Redux store
    const userUpgradedToAnnualPlan = plan.annual;

    // This function will be triggered after the upgrade.
    const showUpgradeSuccessModal = ({ subscriptionId, subscriptionConfirmationUrl, clientSecret }) => {
      const hasSubscriptionConfirmationUrl =
        subscriptionConfirmationUrl !== undefined &&
        subscriptionConfirmationUrl !== null &&
        subscriptionConfirmationUrl &&
        subscriptionConfirmationUrl.length > 0;

      if (!hasSubscriptionConfirmationUrl) {
        postUpgradeTrackingEvents({
          plan,
          subscriptionId,
          undefined
        });
      }

      localStorage.removeItem("landing_page_signup");

      dispatch(modalClose());
      dispatch(isUpgrading(false));

      if (plan.alias === "Promotional") {
        plan.alias = "Pro";
      }

      // Post Upgrade redirect
      const modalToBeDisplayed = userUpgradedToAnnualPlan ? "UPGRADE_SUCCESS" : "ANNUAL_PROMOTION_MODAL";
      const paramsHash = {
        upgrade_modal: modalToBeDisplayed,
        plan_name: plan.name,
        annual: plan.annual,
        has_subscription_confirmation_url: hasSubscriptionConfirmationUrl,
        confirmation_url: subscriptionConfirmationUrl
      };
      const queryString = Object.keys(paramsHash)
        .map(key => key + "=" + paramsHash[key])
        .join("&");
      navigate(`${paths.settings.chargebeePaymentSuccess}?${queryString}`); // TODO: make hook or sth
    };

    // Building the requestPayload to be send to createPlanCharge() and createSubscription() actions
    const requestPayload = {
      plan_id: planID,
      coupon_uid: couponUid,
      upgrade_with_store: createStoreAfterUpgrade,
      tracking_plan_upgrade: {
        ...tracker
      },
      "h-captcha-response": hCaptchaToken
    };

    return createSubscription({ storeId, payload: requestPayload })
      .then(res => {
        const { data } = res || {};
        const {
          id: subscriptionId,
          confirmation_url: subscriptionConfirmationUrl,
          last_payment_client_secret: clientSecret,
          payment_method: paymentMethod
        } = data;

        if (clientSecret) {
          const { uid: paymentMethodId } = paymentMethod;
          return confirmCardPayment3ds({
            clientSecret,
            subscriptionId,
            paymentMethod: paymentMethodId,
            showUpgradeSuccessModal,
            dispatch,
            navigate,
            storeId
          });
        }

        triggerConversionEvent();

        return showUpgradeSuccessModal({ subscriptionId, subscriptionConfirmationUrl });
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

// TODO Maybe we can remove this code since isn't used
export function updatePlanChargeReason(upgradeReasons, upgradeComment, navigate) {
  return function(dispatch, getState) {
    ApiCall.post(`/stores/update_plan_charge_reason`, {
      upgrade_reasons: upgradeReasons,
      upgrade_comment: upgradeComment
    })
      .then(async () => {
        dispatch(closeModal());
        const selectedProduct = getState().ui.selectedProduct;

        const isPathnameSearchPage = window.location.pathname === paths.app.search;
        const listingIDToBeAddedToImportList = localStorage.getItem("listing_id_to_be_added_to_import_list");

        if (listingIDToBeAddedToImportList) {
          localStorage.removeItem("listing_id_to_be_added_to_import_list");

          if (!isPathnameSearchPage) {
            navigate(paths.app.search); // TODO: make hook or sth
          }
          if (selectedProduct) {
            dispatch(clearSelectedProduct());
            navigate(`${paths.product.index}/${selectedProduct.productId}`); // TODO: make hook or sth
          }
        } else {
          !isPathnameSearchPage && navigate(paths.app.search); // TODO: make hook or sth
          if (selectedProduct) {
            dispatch(clearSelectedProduct());
            navigate(`${paths.product.index}/${selectedProduct.productId}`); // TODO: make hook or sth
          }
        }
      })
      .catch(error => {
        dispatch(setAlertMessage(error.json.errors, "error"));
      });
  };
}

export function isUpgrading(isUpgrading) {
  return {
    type: IS_UPGRADING,
    payload: {
      isUpgrading: isUpgrading
    }
  };
}

export function upgradeUrl(json) {
  return {
    type: UPGRADE_URL,
    payload: {
      url: json.confirmation_url
    }
  };
}

export function upgradeError(json) {
  return {
    type: UPGRADE_ERROR,
    payload: {
      error: json.message
    }
  };
}

export function openUpgradeModal(type) {
  return function(dispatch) {
    dispatch(modalOpen());
    dispatch(modalUpgradeType(type));
  };
}

export function modalUpgradeType(type) {
  return {
    type: MODAL_UPGRADE_TYPE,
    payload: {
      modalUpgradeType: type
    }
  };
}

export function modalOpen() {
  return {
    type: MODAL_OPEN,
    payload: {
      modalOpen: true
    }
  };
}

export function closeUpgradeModal() {
  return function(dispatch) {
    dispatch(modalClose());
  };
}

export function modalClose() {
  return {
    type: MODAL_CLOSE,
    payload: {
      modalOpen: false
    }
  };
}
