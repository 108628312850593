import styled, { css } from "styled-components";

const TooltipContainer = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const getTransformValue = position => {
  switch (position) {
    case "left":
      return "translateY(50%)";
    case "right":
      return "translateY(50%)";
    case "top-left":
      return "translateX(-100%)";
    default:
      return "unset";
  }
};

const getTopPosition = position => {
  switch (position) {
    case "bottom":
      return "calc(100% + 4px)";
    default:
      return "unset";
  }
};

const getRightPosition = (position, bubbleWidth, width) => {
  switch (position) {
    case "top-right":
      return "0px";
    case "top-left":
      return `${width}px`;
    case "left":
      return `${bubbleWidth}px`;
    default:
      return "unset";
  }
};

const getLeftPosition = position => {
  switch (position) {
    case "top-left":
      return "0px";
    case "right":
      return "calc(100% + 4px)";
    default:
      return "unset";
  }
};

const getBottomPosition = (position, offsetHeight) => {
  switch (position) {
    case "left":
      return "50%";
    case "right":
      return "50%";
    case "bottom":
      return "unset";
    default:
      return `${offsetHeight + 6}px`;
  }
};

const TooltipBubble = styled.div`
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : "120px")};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "210px")};
  width: ${props => (props.width ? `${props.width}px` : "100%")};
  position: absolute;
  z-index: 1003;
  border-radius: 4px;
  background-color: #222939;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #ffffff;
  padding: 8px;
  animation: fadein 0.2s ease-in;

  @media (max-width: 768px) {
    width: 100%;
    ${props =>
      props.left &&
      css`
        left: ${props.left};
      `}
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* Position TOP */
  top: ${props => getTopPosition(props.position)};
  bottom: ${props => getBottomPosition(props.position, props.offsetHeight)};
  right: ${props => getRightPosition(props.position, props.bubbleWidth, props.width)};
  left: ${props => getLeftPosition(props.position)};
  transform: ${props => getTransformValue(props.position)};
`;

const TooltipMessage = styled.div`
  position: relative;
`;

const TooltipTrigger = styled.div`
  display: inline-block;
`;

export { TooltipContainer, TooltipBubble, TooltipMessage, TooltipTrigger };
