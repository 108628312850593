import { lazy } from "react";
import paths from "../paths";
import { withPageWrapper } from "components/PageWrapper";

const Settings = lazy(() => import("components/Settings").then(module => ({ default: module.Settings })));
const ChargebeePaymentSuccess = lazy(() => import("pages/ChargebeePaymentSuccess"));
const Paypal = lazy(() => import("pages/PayPal/index"));

export const settingsRoutes = [
  { path: paths.settings.index, Component: withPageWrapper(Settings), app: true },
  { path: paths.settings.plans, Component: withPageWrapper(Settings), app: true },
  { path: paths.settings.account, Component: withPageWrapper(Settings), app: true },
  { path: paths.settings.membership, Component: withPageWrapper(Settings), app: true },
  { path: paths.settings.store, Component: withPageWrapper(Settings), app: true },
  { path: paths.settings.pricing, Component: withPageWrapper(Settings), app: true },
  {
    path: paths.settings.chargebeePaymentSuccess,
    Component: withPageWrapper(ChargebeePaymentSuccess),
    app: true
  },
  // paypal
  { path: paths.settings.paypalPaymentSuccess, Component: withPageWrapper(Paypal), app: true },
  { path: paths.settings.paypalPaymentCancel, Component: withPageWrapper(Paypal), app: true }
];
