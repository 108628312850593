import { lazy } from "react";
import paths from "../paths";
import { withPageWrapper } from "components/PageWrapper";

const Search = lazy(() => import("pages/search/index"));
const AliScraper = lazy(() => import("pages/AliScraper").then(module => ({ default: module.AliScraper })));
const AliScraperProduct = lazy(() =>
  import("pages/AliScraperProduct").then(module => ({ default: module.AliScraperProduct }))
);
const ImageSearch = lazy(() => import("pages/ImageSearch").then(module => ({ default: module.ImageSearch })));
const Chat = lazy(() => import("pages/chat").then(module => ({ default: module.Chat })));
const Maintenance = lazy(() => import("pages/Maintenance").then(module => ({ default: module.Maintenance })));
const Orders = lazy(() => import("pages/Orders").then(module => ({ default: module.Orders })));
const Notifications = lazy(() =>
  import("pages/Notifications").then(module => ({ default: module.Notifications }))
);
const PageRedirect = lazy(() =>
  import("pages/PageRedirect").then(module => ({ default: module.PageRedirect }))
);
const SwitchStoreUpgradeSuccess = lazy(() =>
  import("pages/SwitchStoreUpgradeSuccess").then(module => ({ default: module.SwitchStoreUpgradeSuccess }))
);

const CancelSubscription = lazy(() =>
  import("pages/CancelSubscription").then(module => ({ default: module.CancelSubscription }))
);

const WhiteLabelProducts = lazy(() =>
  import("pages/WhiteLabelProducts").then(module => ({ default: module.WhiteLabelProducts }))
);

const AlibabaTemuProducts = lazy(() =>
  import("pages/AlibabaTemuProducts").then(module => ({ default: module.AlibabaTemuProducts }))
);

export const appRoutes = [
  { path: paths.app.search, Component: withPageWrapper(Search), app: true },
  {
    path: paths.app.aliscraper,
    Component: withPageWrapper(AliScraper),
    app: true,
    blockedForAmazonUsers: true
  },
  {
    path: `${paths.app.aliscraper}/:productId`,
    Component: withPageWrapper(AliScraperProduct),
    app: true,
    blockedForAmazonUsers: true
  },
  {
    path: paths.app.imageSearch,
    Component: withPageWrapper(ImageSearch),
    app: true,
    blockedForAmazonUsers: true
  },
  { path: paths.app.jubilee, Component: withPageWrapper(WhiteLabelProducts), app: true },
  { path: paths.app.alibabaTemu, Component: withPageWrapper(AlibabaTemuProducts), app: true },
  { path: paths.app.chat, Component: withPageWrapper(Chat), app: true },
  { path: paths.app.maintenance, Component: withPageWrapper(Maintenance) },
  { path: paths.app.orders, Component: withPageWrapper(Orders), app: true, shop: true },
  { path: paths.app.notifications, Component: withPageWrapper(Notifications), app: true },
  { path: paths.app.cancelSubscription, Component: withPageWrapper(CancelSubscription), app: true },
  { path: `${paths.app.pageRedirect}/:slug`, Component: withPageWrapper(PageRedirect), app: true },
  {
    path: `${paths.app.switchStoreUpgradeSuccess}/:slug`,
    Component: withPageWrapper(SwitchStoreUpgradeSuccess),
    app: true
  }
];
