export const PAYMENT_PROVIDERS = {
  CHARGEBEE: "chargebee",
  STRIPE: "stripe"
};

export const CHARGEBEE_VALIDATION_ERROR_TEXT =
  "There is a problem validating your form. Did you fill all the fields?";

export const CHARGEBEE_VALIDATION_ERROR_EXPIRY_TEXT =
  "Your credit card has expired. Please use a new card to process your transaction.";

export const IMPACT_RADIUS_EVENTS = {
  APP_INSTALL: 35618,
  TRIAL: 35619
};

export const CHAT_TOPIC_LABEL_MAP = {
  general: "General Inquiry",
  product: "Product Inquiry",
  delivery_issues: "Delivered Order Issues",
  processing: "Processing/Shipping Status",
  refund: "Refund",
  feedback: "Feedback",
  others: "Others",
  welcome: "Welcome to Spocket"
};

export const APP_ASSETS_BUCKET_URL = "https://d3eyb8shadqthh.cloudfront.net";

export const APP_LANGUAGES = ["fr", "pt", "de", "it", "es", "en"];

export const ALL_PAGES_URLS = [
  "/aliscraper",
  "/alibaba-temu",
  "/winning-products",
  "/jubilee",
  "/orders",
  "/connect",
  "/chat",
  "/notifications",
  "/search",
  "/import",
  "/products"
];
