// Libs
// Constants
import { APP_ASSETS_BUCKET_URL } from "constants/common";
import PropTypes from "prop-types";
import React from "react";

// Styles
import { StyledImage, Wrapper } from "components/spocketUI/components/AlertMessage/AlertMessageStyle";

// Images
const InfoIcon = `${APP_ASSETS_BUCKET_URL}/info-icon-no-fill.svg`;
export const AlertMessage = ({ text, className, backgroundColor, icon }) => {
  return (
    <Wrapper backgroundColor={backgroundColor} className={className}>
      <StyledImage src={icon} />
      {text}
    </Wrapper>
  );
};

AlertMessage.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  icon: PropTypes.node
};

AlertMessage.defaultProps = {
  className: "",
  text: "",
  backgroundColor: "",
  icon: InfoIcon
};
