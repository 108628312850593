import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

// Utils
import { media } from "utils/media";

// Components
import { Link } from "react-router-dom";
import { Typography } from "components/spocketUI";

// TODO add theme colors on the media query styles

// Style
const Container = styled.div`
  box-sizing: border-box;
  margin-right: 14px;
  border-width: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: ${props => (props.isActive ? props.theme.colors.white.colorWhite : "transparent")};

  &:hover,
  &:focus {
    background-color: ${props =>
      props.isActive ? props.theme.colors.white.colorWhite : props.theme.colors.grey.colorGrey30};
  }

  ${props =>
    props.isActive
      ? css`
          ${media.TABLET`
            border-left: 6px solid #7020FF; background-color: #e6e8f0;
          `}

          ${media.PHONE`
            border-left: none;
            background: #ffffff;
          `}
        `
      : css`
          ${media.TABLET`border-left: none; background-color: transparent;`}
          ${media.PHONE`
            border-left: none;
            background: #ffffff;
          `}
        `};

  ${media.LARGE_DESKTOP`
    max-width: 20%;
  `};

  ${media.TABLET`
    height: 45px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    margin: 6px 0;
    max-width: 100%;
    &:hover,
    &:focus {
      background-color: #e6e8f0;
    }
  `};

  ${media.PHONE`
    border-radius: 6px;
    margin-block: 8px;

     &:hover,
    &:focus {
      background-color: #ffffff;
    }
  `}
`;

const TabLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 16px 30px;
  position: relative;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${media.LARGE_DESKTOP`
    padding: 16px 20px;
  `};

  ${media.SMALL_DESKTOP`
    padding: 16px 12px;
  `};

  ${media.TABLET`
    padding: 0;
  `};
`;

const Image = styled.img`
  max-width: 24px;
  max-height: 24px;
  margin-right: 8px;
  opacity: ${props => (props.isActive ? 1 : 0.7)};
  filter: ${props =>
    props.isActive
      ? "invert(1%) sepia(100%) saturate(8630%) hue-rotate(275deg) brightness(100%) contrast(100%)" // Convert image to #7020FF
      : "none"};

  ${props => (props.isActive ? `${media.TABLET`margin-left: 8px;`}` : `${media.TABLET`margin-left: 14px;`}`)};

  ${media.PHONE`
    margin-left: 24px;
  `}
`;

const Title = styled(Typography.p)`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  padding-bottom: 0;
  text-align: center;
  opacity: ${props => (props.isActive ? 1 : 0.7)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props =>
    props.isActive ? props.theme.colors.purple.colorPurple15 : props.theme.colors.blue.colorBlue10};

  ${media.PHONE`
    padding-left: 4px;
  `}
`;

export const SingleTab = ({ tab, activeRoute, onSelectTab, children }) => {
  const { path, image, title } = tab;
  const isActive = activeRoute === path;

  const handleSelectTab = tabPath => {
    if (!onSelectTab) return;
    onSelectTab(tabPath);
  };

  return (
    <Container
      isActive={isActive}
      data-testid={`ui-tab-${path}`}
      data-cy={`menu-tab-${path}`}
      data-heap={`tab-${path}`}
      onClick={() => handleSelectTab(path)}
    >
      <TabLink as={onSelectTab ? "a" : ""} to={`/settings/${path}`} title={title}>
        <Image src={image} alt={`Tab icon - ${title}`} isActive={isActive} aria-label="ui-tab-image" />
        <Title isActive={isActive}>{title}</Title>
        {children}
      </TabLink>
    </Container>
  );
};

SingleTab.propTypes = {
  tab: PropTypes.object.isRequired,
  activeRoute: PropTypes.string.isRequired
};
