import axiosLib from "axios";

// Utils
import {
  sendGet,
  sendPost,
  sendPut,
  sendDelete,
  sendPostWithoutSignOut,
  sendGetWithoutSignOut,
  sendDeleteWithoutSignOut
} from "../baseUtils";

const host = process.env.REACT_APP_API_URL;

let axios;

export const refreshPayPalPaymentMethods = () => {
  return sendGet("/marketplace/payment_methods/refresh").then(res => {
    return res.data;
  });
};

export const getChargebeePaymentInfo = () => sendGet("/chargebee_integrations/payment_sources");

export const getStripePaymentInfo = () => sendGet("/stripe_integrations/payment_methods?account_source=new");

export const getStripeSubscriptionInfo = () => sendGet("/stripe_integrations/subscriptions/payment_methods");

export const authorizePayPalOrder = async ({ source, authorizationId }) =>
  sendPost(`/marketplace/payment_methods/${source}/authorize`, {
    authorization_id: authorizationId
  });

export const deletePaymentMethod = async methodId => sendDelete(`/marketplace/payment_methods/${methodId}`);

export const deleteStripe = ({ id }) => sendDelete(`/stripe_integrations/payment_methods/${id}`);

export const deleteCreditCard = ({ isStripe, id }) =>
  sendDelete(
    isStripe
      ? `/stripe_integrations/payment_methods/${id}?account_source=new`
      : `/chargebee_integrations/payment_sources/${id}`
  );

export const createPaymentCharge = async ({ store, planId, upgradeWithStore = false }) => {
  if (store === "wix") return sendPostWithoutSignOut(`/stores/wix/payment_subscribe/${planId}`);
  return sendGetWithoutSignOut(
    `/stores/${store}/payment_subscribe/${planId}?upgrade_with_store=${upgradeWithStore}`
  );
};

export const createSubscription = async ({ storeId, payload }) =>
  sendPostWithoutSignOut(`/stores/${storeId}/subscriptions`, payload);

export const deletePendingSubscription = async ({ storeId }) =>
  sendDeleteWithoutSignOut(`/stores/${storeId}/pending_subscription`);

export const updateStripeSubscriptionPayment = async ({ token }) =>
  sendPostWithoutSignOut("/stripe_integrations/subscription/payment_methods", {
    payment_method_id: token
  });

export const getInvoices = async () => {
  const response = await sendGet("/invoices");
  return response.data;
};

export const getEInvoices = ({ EInvoiceId }) => {
  const response = sendGet(`/invoices/${EInvoiceId}`);
  return response;
};

export const getStripePaymentIntent = ({ queryType }) => {
  const queryParam = queryType === "v2" ? "?account_source=new" : "";
  return sendGet(`/stripe_integrations/subscription/intent${queryParam}`).then(res => res.data);
};

export const getStripePaymentAuthorizationStatus = async () => {
  const response = await sendGet("/subscription");
  return response.data;
};

export const putCancelStripeAuthorizationProcess = async () => {
  const response = await sendPut("/subscription", { confirmation_url: null });
  return response.data;
};

// Creates a local shared instance of Axios if doesn't exist yet.
const checkTemporaryStoreAxiosInit = () => {
  if (!axios) {
    axios = axiosLib.create({
      timeout: 60000,
      headers: {
        common: {
          Accept: "application/json",
          "content-type": "application/json"
        }
      }
    });
  }

  if (axios && !axios.defaults.headers.common.Authorization) {
    const { common } = axios.defaults.headers;
    if (localStorage.getItem("temporary_store_auth_token")) {
      axios.defaults.headers.common = {
        ...common,
        Authorization: `Bearer ${localStorage.getItem("temporary_store_auth_token")}`
      };
    }
  }
};

export const getStripePaymentTemporaryStoreIntent = () => {
  checkTemporaryStoreAxiosInit();
  const temporaryToken = localStorage.getItem("temporary_store_auth_token");
  if (temporaryToken) {
    return axios
      .get(host + "/stripe_integrations/subscription/intent", {})
      .then(res => res.data)
      .catch(err => err);
  } else {
    return null;
  }
};

export const createTemporaryStorePaymentCharge = async ({ store, planId, upgradeWithStore = false }) => {
  const temporaryToken = localStorage.getItem("temporary_store_auth_token");
  checkTemporaryStoreAxiosInit();
  if (temporaryToken) {
    return axios
      .get(host + `/stores/${store}/payment_subscribe/${planId}?upgrade_with_store=${upgradeWithStore}`, {})
      .then(res => res)
      .catch(err => err);
  } else {
    return false;
  }
};

export const updateStripeTemporaryStoreSubscriptionPayment = async ({ token }) => {
  const temporaryToken = localStorage.getItem("temporary_store_auth_token");
  checkTemporaryStoreAxiosInit();
  if (temporaryToken) {
    return axios
      .post(host + "/stripe_integrations/subscription/payment_methods", { payment_method_id: token })
      .then(res => res)
      .catch(err => err);
  } else {
    return false;
  }
};

export const reactivateSubscription = async storeId => {
  return sendPostWithoutSignOut(`/stores/${storeId}/subscriptions/reactivate`);
};
